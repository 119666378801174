<template>
  <div id="title-printing" class="panel">
    <div id="pre-table">
      <div class="table-controls">
        <div class="filter-fields" @keydown.enter="handleFilterfieldsEnter">
          <star-date-picker
            id="date"
            v-model="date"
            label="Date"
            :displayFormat="BDPProps.displayFormat"
            :textFieldProps="BDPProps.TextField"
            :datePickerProps="BDPProps.DatePicker"
            :menuProps="BDPProps.Menu"
            modelFormat="MM/DD/YYYY"
          />
          <v-text-field
            id="invoiceNo"
            label="Invoice"
            v-model="invoiceNo"
            clearable
            maxlength="5"
          />
          <v-text-field id="vin" label="Vin" v-model="vin" clearable />
          <v-text-field
            id="titleNo"
            label="Title Number"
            v-model="titleNo"
            clearable
          />

          <v-checkbox
            id="filterSelect"
            v-model="isFilterByUnprinted"
            label="Unprinted"
          />

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" @click="search()" color="primary" icon>
                <v-icon>search</v-icon>
              </v-btn>
            </template>
            <span>Search</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                @click="
                  resetSearch();
                  search();
                "
                color="red"
                icon
              >
                <v-icon>close</v-icon>
              </v-btn>
            </template>
            <span>Reset</span>
          </v-tooltip>
        </div>
        <div class="control-buttons">
          <v-btn @click="selectUnprinted" color="primary"
            >Select Unprinted</v-btn
          >
          <v-btn
            @click="printSelected"
            color="primary"
            :disabled="selection.length === 0"
            >Print Selected</v-btn
          >
        </div>
      </div>
    </div>

    <div class="bootstrap">
      <b-table
        ref="printTable"
        sticky-header
        :fields="fields"
        :items="titlesDisplayed"
        :per-page="500"
        responsive="sm"
        sort-icon-left
        selectable
        head-variant="light"
        select-mode="multi"
        :no-border-collapse="true"
        :tbody-tr-class="titleClassParser"
        hover
        :busy="isBusy"
        :show-empty="true"
        @sort-changed="selection = []"
        @row-selected="onRowSelected"
        @row-clicked="onRowClicked"
      >
        <template #table-busy>
          <strong class="center align-middle">Loading...</strong>
        </template>
        <template #head(selected)="">
          <v-checkbox
            v-if="hasPrintableTitles"
            :value="allSelected"
            readonly
            @click="allSelectClicked"
          ></v-checkbox>
        </template>
        <template #cell(selected)="{ item, rowSelected }">
          <v-checkbox
            v-if="item.CanIssue"
            :value="rowSelected"
            readonly
          ></v-checkbox>
        </template>
      </b-table>
    </div>

    <v-dialog v-model="showReprintPrompt" max-width="800px">
      <v-card>
        <v-card-title>
          <span class="headline">Please provide a reprinted reason.</span>
        </v-card-title>
        <v-card-text>
          <v-textarea
            v-model="reprintReason"
            label="Reprint Reason"
            @input="reprintError = ''"
            :error-messages="reprintError"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="reprintReason === ''"
            @click="reprintTitles"
            :loading="reprintIsInProgress"
            color="green"
          >
            Print
          </v-btn>
          <v-btn @click="showReprintPrompt = false" color="red"> Cancel </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import dayjs from "dayjs";

// mixins
import transaction from "@/mixins/transaction.mixin.js";

//components
import starDatePicker from "@/components/nonPageComponents/starDatePicker";
import { mapGetters } from "vuex";
import { formatErrorResponse } from "@/assets/js/format.js";

export default {
  mixins: [transaction],
  components: { starDatePicker },
  data() {
    // initialize date to today
    const date = dayjs().hour(0).minute(0).second(0).format("MM/DD/YYYY");

    return {
      date: date,
      defaultDate: date,
      defaultInvoiceNo: this.gregorianToJulian(date),
      invoiceNo: this.gregorianToJulian(date),
      vin: "",
      titleNo: "",
      selection: [],
      titles: [],
      unprintedOnly: false,
      showReprintPrompt: false,
      isFilterByUnprinted: false,
      reprintReason: "",
      // resolveReprintPrompt: null, // this gets set as the resolve function within the reject reason prompt promise in printSelected
      reprintError: "",
      isBusy: true,
      reprintIsInProgress: false,
      fields: [
        { key: "selected", label: "" },
        { key: "VIN", sortable: true },
        { key: "TitleNo", label: "Title Number ", sortable: true },
        {
          formattor(value) {
            if (!value) return "";
            return this._.replace(value, /\s+/g, "-").toLowerCase();
          },
          key: "Reason",
          sortable: true
        },
        { key: "PrintCount", sortable: true }
      ],
      searchErrorHandlers: {
        404() {}
      }
    };
  },
  async created() {
    this.$root.$emit("setLoading", true);
    let isFound = false;

    for (let daysAgo = 1; daysAgo < 4 && !isFound; daysAgo++) {
      this.invoiceNo = (parseInt(this.invoiceNo) - 1).toString();
      await this.search();

      if (this.titles.length > 0) {
        this.defaultDate = this.date;
        this.defaultInvoiceNo = this.invoiceNo;
        isFound = true;
      }
    }

    if (!isFound) {
      this.invoiceNo = this.defaultInvoiceNo;
      await this.search();
    }

    this.$root.$emit("setLoading", false);
  },
  methods: {
    onRowSelected(items) {
      for (let i = 0; i < items.length; i++) {
        if (
          !items[i].CanIssue ||
          (this.unprintedOnly && items[i].PrintCount > 0)
        ) {
          this.$refs.printTable.unselectRow(i);
        }
      }
      this.selection = items;
    },
    onRowClicked(item, index) {
      this.unprintedOnly = false;
      if (!item.CanIssue) {
        this.$refs.printTable.selectRow(index);
      }
    },
    allSelectClicked(event) {
      this.unprintedOnly = false;
      event.stopPropagation();
      if (!this.allSelected) {
        this.$refs.printTable.selectAllRows();
      } else {
        this.$refs.printTable.clearSelected();
      }
    },
    titleClassParser(item) {
      if (item?.PrintCount > 0) return "already-printed";
      if (item?.CanIssue === false) return "title-check-not-ready";
    },
    selectUnprinted() {
      this.unprintedOnly = true;
      this.$refs.printTable.selectAllRows();
    },
    async reprintTitles() {
      this.reprintIsInProgress = true;
      this.reprintError = "";
      let titles;
      try {
        titles = await this.requestTitlePDFs(
          this.titlesToReprint,
          this.reprintReason
        );
      } catch (error) {
        console.error(error);
        this.reprintIsInProgress = false;
        return;
      }
      this.printTitles(titles);
      this.$root.$emit("push-alert", "Titles were queued for reprint.", {
        color: "success"
      });
      this.reprintError = "";
      this.showReprintPrompt = false;
      this.search();
      this.reprintIsInProgress = false;
    },
    async printSelected() {
      const unprintedVins = [];
      this.titlesToReprint = [];

      // divide vins in printed / unprinted arrays
      for (let i = 0; i < this.selection.length; i++) {
        if (this.selection[i].PrintCount > 0) {
          this.titlesToReprint.push(this.selection[i].VIN);
        } else {
          unprintedVins.push(this.selection[i].VIN);
        }
      }

      // handle reprints
      if (this.titlesToReprint.length > 0) {
        this.showReprintPrompt = true;
      }

      // get title pdfs from api
      if (unprintedVins.length > 0) {
        const titlePDFs = await this.requestTitlePDFs(unprintedVins);

        // match titlePDF array sort order to unprintedVins sort order (which matches table sort order)
        titlePDFs.sort((a, b) => {
          return (
            unprintedVins.findIndex(vin => a.vin === vin) -
            unprintedVins.findIndex(vin => b.vin === vin)
          );
        });

        await this.printTitles(titlePDFs);

        this.$root.$emit(
          "push-alert",
          (this.titlesToReprint.length > 0 ? "Unprinted t" : "T") +
            "itles were queued for print.",
          { color: "success" }
        );
        this.search();
      }
    },
    /**
     * Obtains title PDFs from the star api for a given set of vins.
     *
     * @param vins Array A set of vins for which to get title pdfs.
     * @param comment String | null If a comment is provided, the provided vins are assumed to be associated with already printed titles and the comment is the reason for the reprint. If null, vins are assumed to be associated with unprinted titles.
     */
    async requestTitlePDFs(vins, comment = null) {
      let body;
      let url = "";
      const isReprint = comment !== null;

      const errorHandlers = {};
      if (isReprint) {
        errorHandlers[422] = async error => {
          this.reprintError = formatErrorResponse(error);
        };
      }

      if (!isReprint) {
        url += "/titleprintcheck/detail/print/vins";
        body = vins;
      } else {
        url += "/titleprintcheck/reprint/detail/titles";
        body = {
          vins: vins,
          comment: comment
        };
      }

      return await this.$api.requestTitlePDFs(url, body, errorHandlers);
    },
    async printTitles(titles) {
      try {
        const pdfs = [];
        for (let i = 0; i < titles.length; i++) {
          pdfs.push(titles[i].data);
        }
        await this.$hubapp.printBase64PDF({ pdfs: pdfs });
      } catch (error) {
        console.error(error);

        this.$store.dispatch("setGlobalAlertState", {
          title: "Error",
          description:
            "An error occurred while trying to print. Print previously selected titles again?",
          icon: "error",
          actions: [
            {
              text: "Print",
              handler: () => {
                this.$store.dispatch("hideGlobalAlert");
                this.printTitles(titles);
              },
              color: "primary"
            },
            {
              text: "Cancel",
              handler: () => {
                this.$store.dispatch("hideGlobalAlert");
              },
              color: "#E0E0E0"
            }
          ]
        });
      }
    },
    async search() {
      this.isBusy = true;
      this.titles = [];
      this.selection = [];
      let route = "";
      if (this.titleNo.length > 0)
        route +=
          "/titleprintcheck/" +
          this.$store.getters.countyId +
          "/title/" +
          this.titleNo;
      else if (this.vin.length > 0)
        route +=
          "/titleprintcheck/" +
          this.$store.getters.countyId +
          "/vin/" +
          this.vin;
      else if (this.invoiceNo.length > 0)
        route +=
          "/titleprintcheck/" +
          this.$store.getters.countyId +
          "/" +
          this.invoiceNo;
      this.titles =
        (await this.$api.titlePrintCheckSearch(
          route,
          this.searchErrorHandlers
        )) || [];
      this.isBusy = false;
    },
    handleFilterfieldsEnter() {
      this.search();
    },
    resetSearch() {
      this.date = this.defaultDate;
      this.invoiceNo = this.defaultInvoiceNo;
      this.vin = "";
      this.titleNo = "";
    }
  },
  computed: {
    ...mapGetters({ BDPProps: "BDPProps" }),
    hasPrintableTitles() {
      return this.titles.some(title => title.CanIssue);
    },
    titlesDisplayed() {
      if (this.isFilterByUnprinted)
        return this.titles.filter(title => title.PrintCount === 0);

      return this.titles;
    },
    allSelected: {
      get() {
        return this.titlesDisplayed.every(title => {
          if (title.CanIssue !== true || this.selection.includes(title))
            return true;
        });
      }
    }
  },
  watch: {
    invoiceNo(newInvoiceNo) {
      if (newInvoiceNo.length === 5) {
        this.date = dayjs(
          this.julianToGregorian(newInvoiceNo, dayjs(this.date).toDate())
        ).format("MM/DD/YYYY");
      }
    },
    date(newDate) {
      this.invoiceNo = this.gregorianToJulian(newDate);
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/assets/css/global-variables.scss";

$header-height: 120px;

#date {
  padding-bottom: 4px;
}

#pre-table {
  padding: 6px;
}

#pre-table .v-text-field {
  margin: 4px;
}

#title-printing {
  display: flex;
  flex-direction: column;
  height: calc(100% - #{$panel-margin}* 2);
}

.control-buttons button {
  margin: 5px;
  margin-bottom: 15px;
}

::v-deep .b-table-sticky-header {
  max-height: calc(100vh - #{$nav-height} - #{$panel-margin}* 2 - 130px);
}

::v-deep tr.already-printed {
  color: var(--text);
  background-color: lightgreen;
}

::v-deep tr.title-check-not-ready,
::v-deep tr.pending-ncic {
  color: var(--text);
  background-color: palegoldenrod;
}

::v-deep tr.fatal-stop-code {
  color: var(--text);
  background-color: lightcoral;
}

::v-deep th.b-table-sort-icon-left div {
  padding: 22px;
}

::v-deep td {
  vertical-align: middle !important;
  height: 91px;
}

.table-controls,
.filter-fields,
.control-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.table-controls {
  width: 100%;
}
</style>
