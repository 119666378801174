var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"panel",attrs:{"id":"title-printing"}},[_c('div',{attrs:{"id":"pre-table"}},[_c('div',{staticClass:"table-controls"},[_c('div',{staticClass:"filter-fields",on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleFilterfieldsEnter.apply(null, arguments)}}},[_c('star-date-picker',{attrs:{"id":"date","label":"Date","displayFormat":_vm.BDPProps.displayFormat,"textFieldProps":_vm.BDPProps.TextField,"datePickerProps":_vm.BDPProps.DatePicker,"menuProps":_vm.BDPProps.Menu,"modelFormat":"MM/DD/YYYY"},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}}),_c('v-text-field',{attrs:{"id":"invoiceNo","label":"Invoice","clearable":"","maxlength":"5"},model:{value:(_vm.invoiceNo),callback:function ($$v) {_vm.invoiceNo=$$v},expression:"invoiceNo"}}),_c('v-text-field',{attrs:{"id":"vin","label":"Vin","clearable":""},model:{value:(_vm.vin),callback:function ($$v) {_vm.vin=$$v},expression:"vin"}}),_c('v-text-field',{attrs:{"id":"titleNo","label":"Title Number","clearable":""},model:{value:(_vm.titleNo),callback:function ($$v) {_vm.titleNo=$$v},expression:"titleNo"}}),_c('v-checkbox',{attrs:{"id":"filterSelect","label":"Unprinted"},model:{value:(_vm.isFilterByUnprinted),callback:function ($$v) {_vm.isFilterByUnprinted=$$v},expression:"isFilterByUnprinted"}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","icon":""},on:{"click":function($event){return _vm.search()}}},on),[_c('v-icon',[_vm._v("search")])],1)]}}])},[_c('span',[_vm._v("Search")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"red","icon":""},on:{"click":function($event){_vm.resetSearch();
                _vm.search();}}},on),[_c('v-icon',[_vm._v("close")])],1)]}}])},[_c('span',[_vm._v("Reset")])])],1),_c('div',{staticClass:"control-buttons"},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.selectUnprinted}},[_vm._v("Select Unprinted")]),_c('v-btn',{attrs:{"color":"primary","disabled":_vm.selection.length === 0},on:{"click":_vm.printSelected}},[_vm._v("Print Selected")])],1)])]),_c('div',{staticClass:"bootstrap"},[_c('b-table',{ref:"printTable",attrs:{"sticky-header":"","fields":_vm.fields,"items":_vm.titlesDisplayed,"per-page":500,"responsive":"sm","sort-icon-left":"","selectable":"","head-variant":"light","select-mode":"multi","no-border-collapse":true,"tbody-tr-class":_vm.titleClassParser,"hover":"","busy":_vm.isBusy,"show-empty":true},on:{"sort-changed":function($event){_vm.selection = []},"row-selected":_vm.onRowSelected,"row-clicked":_vm.onRowClicked},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('strong',{staticClass:"center align-middle"},[_vm._v("Loading...")])]},proxy:true},{key:"head(selected)",fn:function(){return [(_vm.hasPrintableTitles)?_c('v-checkbox',{attrs:{"value":_vm.allSelected,"readonly":""},on:{"click":_vm.allSelectClicked}}):_vm._e()]},proxy:true},{key:"cell(selected)",fn:function(ref){
                var item = ref.item;
                var rowSelected = ref.rowSelected;
return [(item.CanIssue)?_c('v-checkbox',{attrs:{"value":rowSelected,"readonly":""}}):_vm._e()]}}])})],1),_c('v-dialog',{attrs:{"max-width":"800px"},model:{value:(_vm.showReprintPrompt),callback:function ($$v) {_vm.showReprintPrompt=$$v},expression:"showReprintPrompt"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Please provide a reprinted reason.")])]),_c('v-card-text',[_c('v-textarea',{attrs:{"label":"Reprint Reason","error-messages":_vm.reprintError},on:{"input":function($event){_vm.reprintError = ''}},model:{value:(_vm.reprintReason),callback:function ($$v) {_vm.reprintReason=$$v},expression:"reprintReason"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"disabled":_vm.reprintReason === '',"loading":_vm.reprintIsInProgress,"color":"green"},on:{"click":_vm.reprintTitles}},[_vm._v(" Print ")]),_c('v-btn',{attrs:{"color":"red"},on:{"click":function($event){_vm.showReprintPrompt = false}}},[_vm._v(" Cancel ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }